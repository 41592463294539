.root {
	display: grid;
	width: 100%;
	height: 100%;
}

.rainbow {
	grid-area: 1 / -1;
	max-width: 100%;
}

.image {
	grid-area: 1 / -1;
}
