.root {
	/* Prevent text in the dropdown from wrapping */
	& :global(.hds-dropdown-toggle-button__text) {
		white-space: nowrap;
	}

	@nest :global(.hashidaysConference) & :global(.hds-dropdown-toggle-button) {
		background: linear-gradient(
					var(--token-color-surface-primary),
					var(--token-color-surface-primary)
				)
				padding-box,
			linear-gradient(var(--conferenceGradient)) border-box;
		border-color: transparent;
	}
	@nest :global(.hashidaysConference)
			&
			:global(.hds-dropdown-toggle-button:focus:before) {
		background: linear-gradient(
					var(--token-color-surface-primary),
					var(--token-color-surface-primary)
				)
				padding-box,
			linear-gradient(var(--conferenceGradient)) border-box;
		border-color: transparent;
	}
	@nest :global(.hashidaysConference)
			&
			:global(.hds-dropdown-toggle-button__text) {
		background: linear-gradient(var(--conferenceGradient));
		-webkit-background-clip: text;
		-webkit-text-fill-color: transparent;
		color: unset;
	}
	@nest :global(.hashidaysConference) & :global(.hds-dropdown-toggle-chevron) {
		color: var(--waypoint);
	}

	@nest :global(.hashiconfConference) & :global(.hds-menu-primitive__toggle) {
		background: linear-gradient(var(--conferenceGradient));
		border-color: transparent;
		border-radius: 5px;
	}
	@nest :global(.hashiconfConference) & :global(.hds-dropdown-toggle-button) {
		background: transparent;
		border-color: transparent;
	}

	@nest & :global(.hds-dropdown__content) {
		z-index: 3;
	}
}
